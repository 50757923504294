import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../contexts/auth';
import './styles.css';

const navbarRoutes = {
    1: { route: "/cameras", icon: "fas fa-video", text: "Câmeras" },
    2: { route: "/containers/historicocontainers", icon: "fas fa-chart-bar", text: "Conteiners" },
    3: { route: "/navios", icon: "fas fa-ship", text: "Navios" },
    4: { route: "/cargas", icon: "fas fa-file-alt", text: "Docs Operação" },
    5: { route: "/suprimentos/combustivel", icon: "fas fa-cart-plus", text: "Suprimentos" },
    6: { route: "/veiculos/BuscarMotorista", icon: "fas fa-truck", text: "Autos" },
    7: { route: "/operacoes", icon: "fas fa-weight", text: "2° Pesagem" },
    8: { route: "/comercial/propostas", icon: "fas fa-briefcase", text: "Comercial" },
    9: { route: "/relatorios", icon: "fas fa-chart-pie", text: "Relatórios" },
    10: { route: "/administrador", icon: "fas fa-tools", text: "Administrador" },
    11: { route: "/agendamento/dashboardagendamento", icon: "fas fa-calendar-check", text: "Agendamento" },
    12: { route: "/pesagemavulsa/historicopesagemavulsa", icon: "fas fa-calendar-check", text: "Pesagem Avulsa" }
};

// Aba padrão para todos os usuários
const defaultTab = { route: "/dashboard", icon: "fas fa-home", text: "Home" };

function Navbar() {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    // Carrega as permissões de navegação do usuário ao montar o componente
    useEffect(() => {
        if (user) {
            axios.get(`/api/user-nav/${user.id}`)
                .then(response => {
                    // Filtra e mapeia os resultados para os itens do navbar usando o mapeamento `navbarRoutes`
                    const permittedItems = response.data
                        .map(item => navbarRoutes[item.COD_NAV_BAR])
                        .filter(Boolean); // Remove qualquer entrada inválida
                    setMenuItems([defaultTab, ...permittedItems]); // Adiciona a aba padrão no Home
                })
                .catch(error => {
                    console.error('Erro ao carregar itens de navegação:', error);
                });
        }
    }, [user]);

    const handleNavigation = (route) => {
        setIsActive(false);
        navigate(route);
    };

    const toggleMenu = () => {
        setIsActive(!isActive);
    };

    const handleMenuHover = () => {
        setIsActive(true);
    };

    const handleMenuLeave = () => {
        setIsActive(false);
    };

    return (
        <div id="menu" onMouseEnter={handleMenuHover} onMouseLeave={handleMenuLeave}>
            <div className="menu2">
                <nav className={`menu-horizontal ${isActive ? 'active' : ''} ${isActive ? '' : 'menu-hidden'} ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
                    <ul className="itens-menu">
                        <li onClick={toggleMenu}>
                            <div className="nav_bar">
                                <i className="fas fa-bars"></i>
                            </div>
                        </li>
                        
                        {menuItems.map((item, index) => (
                            <li 
                                key={item.route} 
                                onClick={() => handleNavigation(item.route)} 
                                className={`${location.pathname === item.route ? 'ativo' : ''} ${index === 0 ? 'first-tab' : ''}`}
                            >
                                <div className="nav_bar">
                                    <i className={item.icon}></i>
                                    <span className="menu_text">{item.text}</span>
                                </div>
                            </li>
                        ))}
                        <li onClick={logout}>
                            <div className="nav_bar">
                                <i className="fas fa-sign-out-alt"></i>
                                <span className="menu_text">Sair</span>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Navbar;
